import React from 'react';
import LinkedInImage from '../../assets/linkedin.png'
import MediumImage from '../../assets/medium.png'
import GithubImage from '../../assets/github.svg'
import TiktokImage from '../../assets/tiktok.png'
import InstagramImage from '../../assets/instagram.png'

const Home = () => {
    return (         
        <section id="section-home">
            <div className="home">                
                <div className="left">                 
                    <a style={{"text-decoration": "none", "color" : "black"}}>
                        <div className="greeting">
                            Hi,
                            <br />            
                            I'm Jonathan Darwin        
                        </div>
                    </a>   
                    <div className="tags m-top-10">
                        {generateTag()}
                    </div>
                    <div className="m-top-20">
                        {generateSocialMedia()}
                    </div>
                </div>
            </div>
        </section>
    );
}

const generateTag = () => {
    return getTagData().map(tag => {
        return (
            <span className="tag-item m-right-20">
                {tag}
            </span>
        )  
    })    
}

const generateSocialMedia = () => {
    return getSocialMediaData().map(data => {
        return (
            <a className="m-right-10" target="blank" href={data.link}><img src={data.asset_path} className="img-social-media img-size-small img-rectangle-rounded" /></a>
        )
    })
}
 
const getTagData = () => {
    return [
        "#softwareEngineer",
        "#exGoogleDSCLead",
        "#googleCertifiedAAD"
    ]    
}

const getSocialMediaData = () => {
    return [
        {
            asset_path : LinkedInImage,
            link : "https://www.linkedin.com/in/jonathandarwin/",
        },
        {
            asset_path : MediumImage,
            link : "https://medium.com/@jonathanjojo38",
        },
        {
            asset_path : GithubImage,
            link : "https://github.com/jonathandarwin",
        },
        {
            asset_path : TiktokImage,
            link : "https://tiktok.com/@jonathandarwinn",
        },
        {
            asset_path : InstagramImage,
            link : "https://instagram.com/jonathandarwinn",
        },
    ]
}
export default Home;