import React from 'react';
import './App.css';
import './style.css'
import Navbar from './components/navbar/navbar';
import ScrollTop from './components/navbar/scrolltop';
import Home from './components/home/home';
import Profile from './components/profile/profile';
import Footer from './components/footer/footer';
import Contact from './components/contact/contact';
import CertificateAchievement from './components/certificate-achievement/certificateAchievement';
import Project from './components/project/project';
import Skill from './components/skill/skill';
import WorkingExperience from './components/working-experience/workingExperience';
import Organization from './components/organization/organization';
import headers from './network/APIConfig';
import FeaturedEvent from './components/featured-event/featuredEvent';

function insertLog(){
  if(isLogDisabled()) return

  fetch(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_API_KEY}`)
    .then((response) => response.json())
    .then((data) => {
        return fetch('https://api.jonathandarwin.com/log', {
          method : 'POST',
          headers : headers,
          body : JSON.stringify({
            city : data.city,
            region : data.region,
            countryName : data.country_name,
            source : document.referrer,
            latitude : `${data.latitude}`,
            longitude : `${data.longitude}`
          })
        })
      }
    )  
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => {
      console.log(error)
    })
}

function isLogDisabled() {
  return isDev() || queryNoLog()
}

function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}

function queryNoLog() {
  const queryParams = new URLSearchParams(window.location.search)
  const log = queryParams.get("log")

  if(log == '') return false

  return log == 'false'
}

function App() {

  insertLog();

  return (
    <React.Fragment>
      <Navbar />
      <ScrollTop />
      <Home />
      <Profile />
      <WorkingExperience />
      <Organization />
      <Skill />
      <Project />
      <CertificateAchievement />
      <FeaturedEvent />
      <Contact />
      <Footer />
    </React.Fragment>
  );
}

export default App;
